.App {
  text-align: center;
  background-color: #202124;
  color: white;
}

a {
  color: #b64fc8;
}
a:hover {
  color: #ffffff;
}

.cloudflareLink {
  color: #f79b3b;
}

.eeaLink {
  color: #028889;
}

.small {
  text-align: center;
}
.infoBox {
  width: 640px;
  height: 200px;
  margin: 10px;
  padding: 10px;
  background: #606368;
  border-radius: 25px;
  margin: auto;
}

.row {
  display: flex;
  padding-top: 5px;
}

.column {
  float: left;
}

.left {
  text-align: center;
  width: 50%;
  padding-left: 0%;
  max-width: 43%;
}

.right {
  text-align: center;
  padding-top: 5%;
  width: 50%;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
